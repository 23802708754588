import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { AppShellSkeletonLoaderWithSearch } from '@flick-tech/app-shell';
import { useHasCompletedRegistration } from '@flick-tech/core-hooks';
import { ROUTES } from '@flick-tech/shared-common'

function IndexPage() {
  const router = useRouter();
  const { hasCompletedRegistration, loading: registrationStateLoading } =
    useHasCompletedRegistration();

  useEffect(() => {
    if (registrationStateLoading) return;

    const search = window.location.search;

    if (hasCompletedRegistration) {
      router.push(`${ROUTES.Home}${search}`);
    } else {
      router.push(`${ROUTES.Login.Base}${search}`);
    }
  }, [hasCompletedRegistration, registrationStateLoading, router]);

  return <></>;
}

IndexPage.skeletonLoader = <AppShellSkeletonLoaderWithSearch />;

export default IndexPage;
